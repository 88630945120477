import React from 'react'

export default function Blogs() {
  return (
    <section className="blog container py-5">
          <div className="row px-3">
            <div className="col-md-3 col-lg-3 col-sm-6 d-flex align-items-center block">
              <div className="inner-content">
                <h4 className="text-center my-3 fw-bolder">5G <br /> Converage</h4>
                <img src="images/5g.png" alt="5g" />
                <p className="regular-text my-2">All of our business SIM Only plans provide unlimited bandwidth and access to the 5G network, both of which are powerful features. Please be aware that a 5G-capable gadget is needed. Feel the Power of more with boshhh!</p>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-sm-6 d-flex align-items-center block">
              <div className="inner-content">
                <h4 className="text-center my-3 fw-bolder">Boshhh <br /> Business Guide!</h4>
                <img src="/images/business_guide.png" alt="5g" />
                <p className="regular-text my-2">Boshhh will provide you the most recent advice on how to grow your company using the newest strategies and tools! Make the most of your company by obtaining a business plan from Boshhh!</p>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-sm-6 d-flex align-items-center block">
              <div className="inner-content">
                <h4 className="text-center my-3 fw-bolder">Multi-SIM <br /> Business Guide!</h4>
                <img src="images/multi-sim.png" alt="5g" />
                <p className="regular-text my-2">Utilise many devices? The top business SIM Only plans in the UK are available for discounted prices starting at just £7.50 per month. For free and immediate advice, dial 0111 111 111.</p>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-sm-6 d-flex align-items-center block">
              <div className="inner-content">
                <h4 className="text-center my-3 fw-bolder">Dedicated <br/> Business Team</h4>
                <img src="images/dedicated-team.png" alt="5g" />
                <p className="regular-text my-2">Get the best dedicated service with our business team, with help to find you the best deals to ironing out any issues you have, the team will assist you as fast as possible!</p>
              </div>
            </div>
  
  
          </div>
        </section>
  )
}

import React from 'react'

export default function TextSection() {
  return (
    <section className="mobile_section">
          <div className="container py-2">
            <div className="row">
              <div className="col-md-12 col-lg-12  col-sm-12 text-content">
                <h2 className="h1 fw-bolder text-light text-center text-32">
                  All of our business SIM plans come with unlimited UK texts and minutes, with offers ranging from as little as 3GB per month to unlimited data.
                </h2>
              </div>
              
            </div>
          </div>
        </section>
  )
}

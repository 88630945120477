import React from 'react'

export default function Whatsapp() {
  return (
    <div className='whatsapp-icon'>
      <a href="https://wa.me/+447398871718?text=">
        <img src='images/whatsapp-icon.png' />
      </a>
    </div>
  )
}

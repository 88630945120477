import React from 'react'

export default function CreditBuilder() {
  return (
    <div className='container py-5 credit-builder-section'>
        <h1 className='heading-text'>Credit Builder Plus</h1>
        <p>Your credit score is important, and so is your companies credit score. The main issue for any start up or struggling company is funding due to a poorly presented profile.</p>
        <p>Having a good credit score gives you access to things like credit cards, loans, invoice factoring and overdrafts and can also save you money on interest payments.</p>
        <img className='float-left' src="images/phone.png"  alt="boshhh-business" width="450px"/>
        <p>So, would you agree it makes sense to have the best credit score possible for your business.</p>
        
        <p>Then you need Credit Builder Plus™️ B*</p>
        <p>Credit Builder Plus™️ B* actively builds your credit score, granting you access to the best business supports and interest rates available.</p>
        <p>You may be thinking of other credit building products that exist in the market, but what else do they give you in return?</p>
        <p>The short answer is, nothing.</p>
        <p>Unlike other products in the market, Credit Builder Plus™️ B* is the only credit building product giving you a free sim card with unlimited texts, minutes and data on it.</p>
        <p>You get two products for the price of one, and you can keep your current mobile numbers and manage your telecoms fleet from one portal - sounds too good to be true right?</p>
        <p>Credit Builder Plus™️ B* goes even further for you, too, empowering a radical improvement in your credit score, because you will be offered a loan.</p>
        <p>Your loan is paid back through an affordable monthly subscription and your loan status will be updated immediately after your loan is paid back, proving to the credit reference agencies that you are a reliable player.</p>
        <p>Once this update is recorded, your credit score could make a dramatic improvement</p>
        <p>This is a life-changing difference to you if you have a low credit score, the difference between having the ability to grow your company and have access to all enhancements available in the current market</p>
        <p>In addition to increased access to credit, cheaper interest and unlimited minutes, texts and data sim, you will also get access to a toolbox brimming with revolutionary solutions like a credit report and credit diary that put you back in control of your own destiny.</p>
        <p>So, now you know this is all available to you for the cost of breakfast at your local cafe, doesn’t it make sense to get your free SIM and start building your credit score now?</p>
        <p><a href='https://boshhhbusiness.com'>boshhhbusiness.com</a></p>
    </div>
  )
}
